/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export const BlueprintActionNode = {
	name: NODE_TYPES.ACTION,
	label: "Action",
	icon: "mdi/motion-play",
	opts: BP.ActionRef({
		constraints: { required: true },
		label: "Action",
		description: "Invokes action."
	}),
	editorOptions: {
		displayInPalette: false,
		displayInQuickMenu: false
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR,
		[NODE_OUTPUT_NAMES.ON_FAIL]: NODE_OUTPUTS.ON_FAIL
	})
};

export type TBlueprintActionNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintActionNode["opts"]>;
