/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_TYPE, IBlueprintIDTList } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	assignParentToArrItems,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { applyCodeArg, escapeString, inlineValue } from "../Context/CompileUtil";
import { TypeDescArray } from "../Shared/ITypeDescriptor";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import {
	ISchemaFlowNode,
	ISchemaFlowNodeDefault,
	ISchemaFlowNodeModel,
	ISchemaFlowNodeSpec,
	ISchemaFlowNodeTypeDefinitionMap,
	SchemaFlowNode
} from "./SchemaFlowNode";
import {
	IBlueprintSchemaValidationError,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { validateIDTNode } from "../Context/ParseUtil";
import { CMPL_ITEM_KIND } from "../Shared/ICompletionItem";
import { ISchemaComponent } from "./SchemaComponent";

/**
 * Schema model
 */
export interface ISchemaFlowNodeListModel<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IModelNode<ISchemaFlowNodeList<TTypeDefs>> {
	items: ISchemaFlowNodeModel<TTypeDefs>[];
}

/**
 * Schema spec
 */
export interface ISchemaFlowNodeListSpec<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends Array<ISchemaFlowNodeSpec<TTypeDefs>> {
	entryNode?: ISchemaFlowNodeSpec<TTypeDefs>;
}

/**
 * Schema defaults
 */
export type TSchemaFlowNodeListDefault<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap> = Array<
	ISchemaFlowNodeDefault<TTypeDefs>
>;

/**
 * Schema options
 */
export interface ISchemaFlowNodeListOpts<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IBlueprintSchemaOpts {
	/** Flow node type definitions */
	nodeTypes: TTypeDefs;
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
	/** Entry node configuration */
	entryNode?: {
		/** Entry node type */
		type: ISchemaFlowNodeDefault<TTypeDefs>["type"];
		/** Entry node ID */
		id: ISchemaFlowNodeDefault<TTypeDefs>["id"];
		/** Entry node defult value */
		defaultOpts?: ISchemaFlowNodeDefault<TTypeDefs>["opts"];
		/** Default position */
		defaultPosition?: ISchemaFlowNodeDefault<TTypeDefs>["position"];
	};
}

/**
 * Schema type
 */
export interface ISchemaFlowNodeList<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IBlueprintSchema<
		ISchemaFlowNodeListOpts<TTypeDefs>,
		ISchemaFlowNodeListModel<TTypeDefs>,
		ISchemaFlowNodeListSpec<TTypeDefs>,
		TSchemaFlowNodeListDefault<TTypeDefs>
	> {
	/** Internally used flow node schema */
	flowNodeSchema: ISchemaFlowNode<TTypeDefs>;
	/**
	 * Creates and adds a flow node to the list.
	 * Returns newly created flow node
	 *
	 * @param modelNode Model node instance
	 * @param itemDefault Default value for a new node (must be valid!)
	 * @param notify If to emit change event(s)
	 */
	addItem: (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		itemDefault?: ISchemaFlowNodeDefault<TTypeDefs>,
		notify?: boolean
	) => ISchemaFlowNodeModel<TTypeDefs>;

	/**
	 * Adds an existing flow node to the list
	 *
	 * @param modelNode Model node instance
	 * @param itemNode Existing model of an flow node
	 * @param notify If to emit change event(s)
	 */
	addItemModel: (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		itemNode: ISchemaFlowNodeModel<TTypeDefs>,
		notify?: boolean
	) => void;

	/**
	 * Remove a flow node from the list by its ID
	 *
	 * @param modelNode Model node instance
	 * @param id Flow node ID
	 * @param notify If to emit change event(s)
	 */
	removeItemById: (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		id: string,
		notify?: boolean
	) => ISchemaFlowNodeModel<TTypeDefs>;

	/**
	 * Removes all items except entry node (if specified)
	 *
	 * @param modelNode Model node instance
	 * @param notify If to emit change event(s)
	 */
	removeAllItems: (modelNode: ISchemaFlowNodeListModel<TTypeDefs>, notify?: boolean) => void;

	/**
	 * Returns item model by node ID, or null if not exists
	 */
	getItemById: (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		id: string
	) => ISchemaFlowNodeModel<TTypeDefs>;

	/**
	 * Returns entry node (or null if does not exist or not specified in schema opts)
	 */
	getEntryNode: (modelNode: ISchemaFlowNodeListModel<TTypeDefs>) => ISchemaFlowNodeModel<TTypeDefs>;
}

/**
 * Schema: Component list
 *
 * @param opts Schema options
 */
export function SchemaFlowNodeList<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>(
	opts: ISchemaFlowNodeListOpts<TTypeDefs>
): ISchemaFlowNodeList<TTypeDefs> {
	type TItemModel = ISchemaFlowNodeModel<TTypeDefs>;

	const flowNodeSchema = SchemaFlowNode({
		label: "Flow node",
		constraints: {
			required: true
		},
		nodeTypes: opts.nodeTypes
	});

	const schema = createEmptySchema<ISchemaFlowNodeList<TTypeDefs>>("flowNodeList", opts);

	schema.flowNodeSchema = flowNodeSchema;

	const ensureEntryNode = (dCtx: DesignContext, items: TItemModel[]): void => {
		let hasEntryNode = false;

		for (let i = 0; i < items.length; i++) {
			if (items[i].id === opts.entryNode.id) {
				hasEntryNode = true;
				break;
			}
		}

		if (!hasEntryNode) {
			const entryNode = flowNodeSchema.createDefault(dCtx, null, {
				id: opts.entryNode.id,
				type: opts.entryNode.type,
				opts: opts.entryNode.defaultOpts,
				position: opts.entryNode.defaultPosition,
				outputs: {},
				varName: null
			});

			items.unshift(entryNode);
		}
	};

	const validateItems = (items: TItemModel[]): IBlueprintSchemaValidationError[] => {
		const errors: IBlueprintSchemaValidationError[] = [];
		const idList = [];
		const outputMap: { [K: string]: { item: TItemModel; outputs: { [K: string]: string[] } } } = {};

		// Check ids and create ID list
		for (let i = 0; i < items.length; i++) {
			// Check duplicate ID
			if (idList.includes(items[i].id)) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.DUPLICATE_IDENTIFIER,
					message: `Duplicate node ID '${items[i].id}'. (node at index '${i}')`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:flowNodeList#errors.duplicateId",
						args: {
							id: items[i].id
						},
						parseInfo: items[i].parseInfo?.id
					}
				});
			} else {
				idList.push(items[i].id);
			}

			// Check start node type
			if (
				opts.entryNode &&
				items[i].id === opts.entryNode.id &&
				items[i].type !== opts.entryNode.type
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.CONST,
					message: `Invalid entry node type '${String(items[i].type)}', should be '${String(opts.entryNode.type)}'. (node at index '${i}')`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:flowNodeList#errors.invalidEntryNodeType",
						args: {
							id: items[i].id,
							currentType: items[i].type,
							requiredType: opts.entryNode.type
						},
						parseInfo: items[i].parseInfo?.id
					}
				});
			}
		}

		// Check references
		for (let i = 0; i < items.length; i++) {
			const nodeId = items[i].id;
			const outputs = items[i].outputs.props;

			outputMap[nodeId] = {
				item: items[i],
				outputs: {}
			};

			for (const k in outputs) {
				const targetList = outputs[k].items;
				outputMap[nodeId].outputs[k] = [];

				for (let j = 0; j < targetList.length; j++) {
					const targetId = targetList[j].value;
					outputMap[nodeId].outputs[k].push(targetId);

					// Check if references itself
					if (targetId === nodeId) {
						errors.push({
							type: SCHEMA_VALIDATION_ERROR_TYPE.INVALID_REF,
							message: `Node references itself. (Node ID '${nodeId}' at index '${i}'; Output '${k}', index '${j}'.`,
							metaData: {
								// @todo add to translation table
								translationTerm: "schema:flowNodeList#errors.selfRef",
								args: {
									sourceId: nodeId,
									outputName: k,
									itemIndex: j
								},
								parseInfo: items[i].parseInfo?.outputs[k]?.[j]
							}
						});
					}

					// Check if reference exists
					if (!idList.includes(targetId)) {
						errors.push({
							type: SCHEMA_VALIDATION_ERROR_TYPE.INVALID_REF,
							// eslint-disable-next-line max-len
							message: `Unknown target node '${targetId}'. (Node ID '${nodeId}' at index '${i}'; Output '${k}', index '${j}')`,
							metaData: {
								// @todo add to translation table
								translationTerm: "schema:flowNodeList#errors.invalidRef",
								args: {
									sourceId: nodeId,
									targetId: targetId,
									outputName: k,
									itemIndex: j
								},
								parseInfo: items[i].parseInfo?.outputs[k]?.[j]
							}
						});
					}
				}
			}
		}

		// Check cycle dependencies
		const resolveDeps = (trace: string[], nodeId: string) => {
			if (trace.includes(nodeId)) {
				const _trace = trace.concat([ nodeId ]);

				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.INVALID_REF,
					// eslint-disable-next-line max-len
					message: `Cycle dependency detected: ${_trace.join(" -> ")}.`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:flowNodeList#errors.cycleDep",
						args: {
							trace: _trace
						},
						parseInfo: outputMap[nodeId]?.item?.parseInfo?.id
					}
				});

				return;
			}

			if (outputMap[nodeId]) {
				for (const k in outputMap[nodeId].outputs) {
					outputMap[nodeId].outputs[k].forEach((targetId) =>
						resolveDeps(trace.concat([ nodeId ]), targetId)
					);
				}
			}
		};

		for (const k in outputMap) {
			resolveDeps([], k);
		}

		return errors;
	};

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToArrItems(srcModel, srcModel.items);
	};

	const createModel = (
		dCtx: DesignContext,
		items: TItemModel[],
		parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]
	) => {
		const model = createModelNode(schema, dCtx, parent, validationErrors, {
			items: items
		});

		return assignParentToChildrenOf(model);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		if (defaultValue && !Array.isArray(defaultValue)) {
			throw new SchemaDeclarationError(schema.name, schema.opts, "Default value must be an array.");
		}

		const items = [];

		if (Array.isArray(defaultValue)) {
			for (let i = 0; i < defaultValue.length; i++) {
				const itemNode = flowNodeSchema.createDefault(dCtx, null, defaultValue[i]);

				if (itemNode) {
					items.push(itemNode);
				}
			}
		}

		if (opts.entryNode) {
			ensureEntryNode(dCtx, items);
		}

		const validationErrors = validateItems(items);

		// Create model
		return createModel(dCtx, items, parent, validationErrors);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedItems = modelNode.items.map((cmp) => cmp.schema.clone(dCtx, cmp, null));

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			items: clonedItems
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.items.forEach((item) => item.schema.destroy(item));
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.LIST
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		const items = rootNode.items
			.map((item) => {
				if (idtNode.parseInfo?.loc?.range) {
					flowNodeSchema.provideCompletion(
						dCtx,
						idtNode.parseInfo.loc,
						idtNode.parseInfo.loc.range.start.col + 2,
						item
					);
				}

				if (!item) {
					return null;
				}

				const itemNode = flowNodeSchema.parse(dCtx, item, null);

				if (!itemNode) {
					return null;
				}

				return itemNode;
			})
			.filter((item) => item !== null);

		if (opts.entryNode) {
			ensureEntryNode(dCtx, items);
		}

		const validationErrors = validateItems(items);

		// Log validation errors at right places
		for (let i = 0; i < validationErrors.length; i++) {
			const reportParseInfo = validationErrors[i].metaData.parseInfo || idtNode.parseInfo;

			if (reportParseInfo) {
				dCtx.logParseError(reportParseInfo.loc.uri, {
					range: reportParseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_VALUE,
					message: validationErrors[i].message,
					parsePath: idtNode.path
				});
			}
		}

		return createModel(dCtx, items, parent, validationErrors);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {
		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => [
			{
				kind: CMPL_ITEM_KIND.Text,
				label: "(list element)",
				insertText: `- `
			}
		]);
	};

	schema.serialize = (modelNode, path) => {
		return {
			type: BP_IDT_TYPE.LIST,
			path,
			items: modelNode.items.map((item, index) =>
				flowNodeSchema.serialize(item, path.concat([ `[${index}]` ]))
			)
		} as IBlueprintIDTList;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		const prevItems = prevSpec && Array.isArray(prevSpec) ? prevSpec : [];
		let entryNodeIndex = null;

		const nodes = modelNode.items.map((item, index) => {
			const nodeSpec = item.schema.render(
				rCtx,
				item,
				path.concat([ String(index) ]),
				scope,
				prevItems[index]
			);

			if (opts.entryNode?.id && item.id === opts.entryNode.id) {
				entryNodeIndex = index;
			}

			return nodeSpec;
		});

		return Object.assign(nodes, {
			entryNode: entryNodeIndex ? nodes[entryNodeIndex] : null
		});
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate
		if (!modelNode.isValid) {
			cCtx.logValidationErrors(
				path,
				modelNode.nodeId,
				DOC_ERROR_SEVERITY.ERROR,
				modelNode.validationErrors
			);
		}

		let entryNodeIndex = null;

		const nodesCode = modelNode.items.map((item, index) => {
			const itemCmp = item.schema.compileRender(cCtx, item, path.concat([ String(index) ]));

			if (opts.entryNode?.id && item.id === opts.entryNode.id) {
				entryNodeIndex = index;
			}

			return applyCodeArg(
				itemCmp,
				`_pi[${inlineValue(index)}]`,
				`pt.concat(["${escapeString(String(index))}"])`
			);
		});

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${[
				// Get prev items
				`const _pi=(pv&&Array.isArray(pv)?pv:[]);`,
				// Render items
				`const _n=[${nodesCode.join(",")}];`,
				`return Object.assign(_n,{entryNode:${entryNodeIndex ? `_n[${entryNodeIndex}]` : `null`}})`
			].join("")}}`
		};
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		// Cannot be exported because flowNode cannot be exported
		throw new Error("FlowNodeList schema does not support export.");
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescArray({
			label: opts.label,
			description: opts.description,
			items: modelNode.items.map((item) => item.schema.getTypeDescriptor(item)),
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.addItem = (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		itemDefault?: ISchemaFlowNodeDefault<TTypeDefs>,
		notify?: boolean
	) => {
		const item = flowNodeSchema.createDefault(modelNode.ctx, modelNode, itemDefault);

		modelNode.items.push(item);

		modelNode.validationErrors = validateItems(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item;
	};

	schema.addItemModel = (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		itemNode: ISchemaFlowNodeModel<TTypeDefs>,
		notify?: boolean
	) => {
		itemNode.schema.assignParent(itemNode, modelNode, false);
		modelNode.items.push(itemNode);

		modelNode.validationErrors = validateItems(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.removeItemById = (
		modelNode: ISchemaFlowNodeListModel<TTypeDefs>,
		id: string,
		notify?: boolean
	) => {
		// Find item
		let itemIndex = null;

		for (let i = 0; i < modelNode.items.length; i++) {
			if (modelNode.items[i].id === id) {
				itemIndex = i;
				break;
			}
		}

		if (itemIndex === null) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Cannot remove item ID '${id}' because it does not exist.`
			);
		}

		// Remove item
		const item = modelNode.items.splice(itemIndex, 1);

		// Remove references from other nodes
		for (let i = 0; i < modelNode.items.length; i++) {
			modelNode.items[i].schema.disconnectNode(modelNode.items[i], id, notify);
		}

		// Validate
		modelNode.validationErrors = validateItems(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item[0];
	};

	schema.removeAllItems = (modelNode: ISchemaFlowNodeListModel<TTypeDefs>, notify?: boolean) => {
		// Destroy all items
		for (let i = 0; i < modelNode.items.length; i++) {
			modelNode.items[i].schema.destroy(modelNode.items[i]);
		}

		modelNode.items = [];
		ensureEntryNode(modelNode.ctx, modelNode.items);

		// Validate
		modelNode.validationErrors = validateItems(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.getItemById = (modelNode: ISchemaFlowNodeListModel<TTypeDefs>, id: string) => {
		for (let i = 0; i < modelNode.items.length; i++) {
			if (modelNode.items[i].id === id) {
				return modelNode.items[i];
			}
		}

		return null;
	};

	schema.getEntryNode = (modelNode: ISchemaFlowNodeListModel<TTypeDefs>) => {
		if (!opts.entryNode?.id) {
			return null;
		}

		for (let i = 0; i < modelNode.items.length; i++) {
			if (modelNode.items[i].id === opts.entryNode.id) {
				return modelNode.items[i];
			}
		}

		return null;
	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.items.map((item, index) => ({
			key: "items." + index,
			node: item
		}));
	};

	// Check entry node type
	if (opts.entryNode && !opts.nodeTypes[opts.entryNode.type]) {
		// eslint-disable-next-line max-len
		throw new SchemaDeclarationError(
			schema.name,
			schema.opts,
			`Entry node type '${String(opts.entryNode.type)}' is not in nodeType definitions.`
		);
	}

	return schema;
}
