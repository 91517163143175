/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	createEmptyScope,
	declareFunction,
	IScope,
	OBJECT_TYPE,
	OBJECT_TYPE_PROP_NAME,
	RuntimeContext,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	TModelPath,
	TTypeDesc,
	Type
} from "@hexio_io/hae-lib-blueprint";
import { stringify as stringifyQs, parse as parseQs } from "qs";

export const qsParse = declareFunction({
	name: "QUERYSTRING_PARSE",
	category: "url",
	label: "Parses Query String",
	description: "Parses first argument as query string and returns parsed parameters as an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Query String",
			constraints: {
				required: false
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const qs = args[0]();
		return qs ? parseQs(qs) : {};
	}
});

export const qsSerialize = declareFunction({
	name: "QUERYSTRING_SERIALIZE",
	category: "url",
	label: "Serialize Query String",
	description: "Returns first argument as a serialized query string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Map({
			label: "Query",
			constraints: {
				required: true
			},
			value: BP.Any({
				label: "Value",
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: {}
		}),
		BP.Boolean({
			label: "Skip nulls",
			constraints: {
				required: false
			},
			fallbackValue: false
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const data = args[0]();
		const skipNulls = args[1]();

		return stringifyQs(data, {
			skipNulls: !!skipNulls
		});
	}
});

export const uriEncodeFunc = declareFunction({
	name: "URI_ENCODE",
	category: "url",
	label: "URI Encode",
	description: "Encodes a text string as a valid Uniform Resource Identifier (URI).",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			default: "",
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		return encodeURI(value);
	}
});

export const uriEncodeComponentFunc = declareFunction({
	name: "URI_ENCODE_COMPONENT",
	category: "url",
	label: "URI Encode Component",
	description: "Encodes a text string as a valid component of a Uniform Resource Identifier (URI).",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			default: "",
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		return encodeURIComponent(value);
	}
});

export const uriDecodeFunc = declareFunction({
	name: "URI_DECODE",
	category: "url",
	label: "URI Decode",
	description: "Gets the unencoded version of an encoded Uniform Resource Identifier (URI).",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			default: "",
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		return decodeURI(value);
	}
});

export const uriDecodeComponentFunc = declareFunction({
	name: "URI_DECODE_COMPONENT",
	category: "url",
	label: "URI Decode Component",
	description: "Gets the unencoded version of an encoded component of a Uniform Resource Identifier (URI).",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			default: "",
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		return decodeURIComponent(value);
	}
});

export const parseIntFunc = declareFunction({
	name: "PARSE_INT",
	category: "types",
	label: "Parse Integer",
	description: "Tries to parse integer from string. Returns null if value cannot be parsed.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.Integer({
			label: "Radix",
			constraints: {
				required: false
			},
			default: 10,
			fallbackValue: 10
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const value = args[0]() as string;
		const radix = args[1]() as number;
		const res = parseInt(value, radix);

		if (!isNaN(res)) {
			return res;
		} else {
			return null;
		}
	}
});

export const parseFloatFunc = declareFunction({
	name: "PARSE_FLOAT",
	category: "types",
	label: "Parse Float",
	description: "Tries to parse float from string. Returns null if value cannot be parsed.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const value = args[0]() as string;
		const res = parseFloat(value);

		if (!isNaN(res)) {
			return res;
		} else {
			return null;
		}
	}
});

export const scopedTemplateFunc = declareFunction({
	name: "SCOPED_TEMPLATE",
	category: "util",
	label: "Scoped Template",
	description:
		"Returns a scoped template functions that can be used in formatters and other dynamic fields.\
 Scoped template works like an anonymous function that is called later for some items that are provided in a scope.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args, _restArgs, _scope) => {
		return (scope: IScope | ((parentScope: IScope) => IScope)) => {
			let childScope = typeof scope === "function" ? scope(_scope) : scope;

			// Check for valid scope - if not, create a new one
			// This is mainly because of test framework which calls the every fucking function when comparing data
			if (!(childScope?.globalData instanceof Object)) {
				childScope = createEmptyScope();
			}

			return args[0](childScope.globalData, childScope.globalType.props);
		};
	}
});

export const letFunc = declareFunction({
	name: "LET",
	category: "util",
	label: "Variable",
	description:
		"Defines a variable that is available in a nested expression.\
		\
		Example:  \
		`LET(\"myName\", \"john\", \"Hello, \" + myName)`",
	argRequiredCount: 3,
	argSchemas: [
		BP.String({
			label: "Variable Name",
			constraints: {
				required: true
			},
			fallbackValue: "_"
		}),
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
		}),
		BP.Any({
			label: "Expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: false
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const varName = args[0]() as string;
		const value = args[1]();

		return args[2](
			{ [varName]: value },
			{ [varName]: Type.Any({}) }
		)
	}
});

export const lambdaFunc = declareFunction({
	name: "LAMBDA",
	category: "util",
	label: "Lambda Function",
	description: `Defines an anonymous function that can be passed as a value.

**Example:**
	
\`\`\`
LET(
  "mySum",
  LAMBDA([ "arg1", "arg2" ], arg1 + arg2),
  mySum(1, 2)
)
\`\`\``,
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Variable Name",
			items: BP.String({
				label: "Argument Name",
				constraints: {
					required: true
				}
			}),
			constraints: {
				required: false
			},
			fallbackValue: []
		}),
		BP.Any({
			label: "Body Expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: false
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const argNames = args[0]() as string[];
		const bodyFn = args[1];

		const executor = (
			_rCtx: RuntimeContext,
			_path: TModelPath,
			_modelNodeId: number,
			scope: IScope,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			args: Array<(scope: IScope) => any>
		) => {
			const argValues: Record<string, unknown> = {};
			const argTypes: Record<string, TTypeDesc> = {};

			for (let i = 0; i < argNames.length; i++) {
				const argName = argNames[i];

				argValues[argName] = args[i] ? args[i](scope) : null;
				argTypes[argName] = Type.Any({});
			}

			return bodyFn(argValues, argTypes);
		}

		Object.defineProperty(executor, "name", { value: `functionExecutor[lambda]` });
		Object.defineProperty(executor, "toString", { value: () => `function[lambda]` });
		executor[OBJECT_TYPE_PROP_NAME] = OBJECT_TYPE.FUNCTION;

		return executor;
	}
});

export const classlistFunc = declareFunction({
	name: "CLASSLIST",
	category: "util",
	label: "CSS Class List",
	description: `Returns a CSS class list from string->boolean map.

**Example:**
	
\`\`\`
CLASSLIST(
  {
    "class1": true,
    "class2": false
  }
)
\`\`\``,
	argRequiredCount: 1,
	argSchemas: [
		BP.Map({
			label: "Classes",
			constraints: {
				required: true
			},
			fallbackValue: {},
			value: BP.Boolean({
				label: "Enabled",
				constraints: {
					required: true
				},
				fallbackValue: false,
				default: false
			})
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const classes = args[0]();

		return Object.keys(classes)
			.filter((className) => classes[className])
			.join(" ");
	}
});

export const bemFunc = declareFunction({
	name: "BEM",
	category: "util",
	label: "BEM",
	description: `Returns a CSS class list for BEM (Block Element Modifier) system.

**Example:**
	
\`\`\`
BEM(
  "user-card",
  "button",
  {
    "is-active": true,
    "is-disabled": false
  }
)
\`\`\``,
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Block Name",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Element Name",
			constraints: {
				required: false
			},
			fallbackValue: ""
		}),
		BP.Map({
			label: "Modifiers",
			constraints: {
				required: true
			},
			fallbackValue: {},
			value: BP.Boolean({
				label: "Enabled",
				constraints: {
					required: true
				},
				fallbackValue: false,
				default: false
			})
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const blockName = args[0]();
		const elementName = args[1]();
		const modifiers = args[2]() as Record<string, boolean>;

		const baseClass = elementName ? `${blockName}__${elementName}` : blockName;
		const classes = [ baseClass ];

		for (const modifierName in modifiers) {
			if (modifiers[modifierName]) {
				classes.push(`${baseClass}--${modifierName}`);
			}
		}

		return classes.join(" ");
	}
});
