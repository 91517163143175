/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	TAllNodesSpec,
	TBlueprintActionNodeOptsSchemaSpec_v2,
	TBlueprintActionNodeTypes
} from "../../blueprints";
import { NODE_RESULT_TYPE, IActionResultError, TActionResult } from "../IActionResult";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";

export async function actionNodeHandler_v2<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintActionNodeOptsSchemaSpec_v2,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { action, runInBackground } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["action@v2"]["opts"]>;
	const { app, appEnvId, appId, appName, config, context } = aCtx;

	if (runInBackground) {
		const processId = await app
			.get("actionManager")
			.invokeOnBackground(action.actionId, action.params, context, config, appId, appEnvId, appName);

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: {
					processId
				},
				typeDescriptor: Type.Object({
					props: {
						processId: Type.Integer({})
					}
				})
			},
			aCtx,
			nCtx
		);
	} else {
		try {
			const actionResult = (await app
				.get("actionManager")
				.invoke(action.actionId, action.params, context, config, appId, appEnvId, appName)) as TActionResult;
	
			if (actionResult.status === NODE_RESULT_TYPE.SUCCESS) {
				return createSuccessNodeResult(
					{
						opts,
						outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
						data: actionResult.data,
						typeDescriptor: Type.Any({})
					},
					aCtx,
					nCtx
				);
			} else {
				const actionErrorResult = actionResult as IActionResultError;
				return createErrorNodeResult(
					{
						opts,
						outputName: NODE_OUTPUT_NAMES.ON_ERROR,
						data: {
							name: "Unknown Nested Action Error.",
							code: ERROR_CODES.ACTION_NODE,
							message: "Can't process nested action invocation response."
						},
						debugDetail: { nestedAction: actionErrorResult.debug },
						typeDescriptor: Type.Any({}),
						nestedActionErrorResult: actionErrorResult
					},
					aCtx,
					nCtx
				);
			}
		} catch (error) {
			return createErrorNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					error,
					data: {
						message: error?.message || "Unknown internal action error.",
						name: ERROR_NAMES.ACTION_NODE,
						code: ERROR_CODES.ACTION_NODE
					},
					typeDescriptor: Type.Object({
						props: {
							message: Type.String({}),
							name: Type.String({})
						}
					})
				},
				aCtx,
				nCtx
			);
		}
	}
}
